:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
}

.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 150px 0px 150px;
    background-color: var(--background-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    position: sticky;
    top: 0;

}

.darkmode {
    background-color: var(--background-color);
    border: none;
    color: var(--text-color);
    font-size: 1.2rem;
    cursor: pointer;
}

.lightbulb {
    height: auto;
    width: 2rem;
    padding: 5px;
    cursor: pointer;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--text-color);
    text-decoration: none;
}

.menu {
    display: flex;
    align-items: center;
    position: relative;
}

.menu ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navigation.open .menu ul {
    display: flex;
    background-color: var(--background-color);
}

.menu ul li {
    margin-left: 10px;
}

.menu-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    height: 3px;
    background-color: black;
    margin: 3px;
    transition: all 0.3s ease;
}

.open .line:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
}

.open .line:nth-child(2) {
    opacity: 0;
}

.open .line:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 700px) {
    .navigation {
        padding: 0px 20px 0px 20px;
    }

    .menu ul {
        display: none;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.8);
        /* padding-right: 100px; */
        margin-left: -10rem;
        padding: 1rem;
        position: absolute;
        top: 100%;
        font-size: 1.5rem;
        /* left: 0; */
        /* width: 100rem; */
        backdrop-filter: blur(10px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    }

    .menu ul li {
        margin-left: 0;
        margin-bottom: 10px;
    }

    .menu-toggle {
        display: flex;
    }
}


.menu ul li a {
    color: var(--text-color);
    text-decoration: none;
    transition: background-color 0.3s ease;
    cursor: pointer;
    font-size: 1.2rem;
}

.menu ul li a:hover {
    background-color: rgba(0, 0, 0, 0.1);
}