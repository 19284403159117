:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    background-color: var(--background-color);
}



.blog-post-container {
    display: flex;
    align-items: flex-start;
    padding: 40px;
    height: 100%;
    box-sizing: border-box;
    justify-content: center;
    background-color: var(--background-color);
}

.blog-post {
    background-color: var(--background-color);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1);
    padding: 20px;
    /* max-width: 800px; */
    width: 100%;
    margin-right: 40px;
}

.blog-post p {
    font-size: 1.5rem;
    line-height: 1.6;
}

.blog-post-title {
    font-size: 5rem;
    margin-bottom: 20px;
    color: var(--text-color);
}

.blogImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.imgDiv {
    display: flex;
    justify-content: center;
}

.blog-post-content p {
    font-size: 1.5rem;
    line-height: 1.6;

}

.other-posts {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 20%;

}

.other-posts h4 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2rem;
    color: var(--text-color);
    text-decoration: none;
}

.other-posts p {
    font-size: 1.5rem;
    line-height: 1.6;
}

.other-posts-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
}

.other-post {
    background-color: var(--background-color);
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 1);

}

.home-link {
    display: block;
    margin-bottom: 10px;
    font-size: 16px;
    color: #888;
    text-decoration: none;
}

.home-link:hover {
    text-decoration: underline;
}

h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

@media screen and (max-width: 800px) {
    .blog-post-container {
        flex-direction: column;
        align-items: center;
    }

    .blog-post {
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .other-posts {
        width: 100%;
    }

    .blog-post-title {
        font-size: 3rem;
    }
}