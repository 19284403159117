:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
    --about-background-color: #ffffff;
    --about-text-color: #333333;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    --about-background-color: rgb(24, 24, 24);
    --about-text-color: white;
}

.contact-container {
    display: flex;
    justify-content: center;
    /* padding: 20px; */
    /* margin: 100px; */
    justify-content: center;
    padding: 100px;
    background-color: var(--background-color);
}

h2 {
    font-size: 6rem;
    margin-bottom: 20px;
    color: #333333;
}

p {
    font-size: 3rem;
    line-height: 1.6;
    color: #666666;
}



.contact-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-picture {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-picture img {
    max-width: 100%;
}

.form-inputs {
    display: flex;
    flex-direction: column;
}

.contact-form input,
.contact-form textarea {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 2rem;
}

.contact-form button:hover {
    background-color: #0069d9;
}

@media (max-width: 800px) {


    .section {
        padding: 40px;
    }
}