:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
    --about-background-color: #ffffff;
    --about-text-color: #333333;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    --about-background-color: rgb(24, 24, 24);
    --about-text-color: white;
}

.education-list {
    display: flex;
    flex-direction: column;
}

.education-item {
    cursor: pointer;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s;
}

.education-item:hover {
    background-color: #2c2c2c;
}

.arrow {
    display: inline-block;
    transform: rotate(90deg);
}

h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.education-section {
    background-color: var(--background-color);
    padding: 200px;
    /* margin-bottom: 20px; */
}

.education-item h3 {
    margin: 0;
    font-size: 2rem;
    color: var(--text-color);
}

.education-item p {
    margin-top: 5px;
    display: none;
}

.education-item.active p {
    display: block;
}

@media (max-width: 800px) {
    .section {
        padding: 40px;
    }
}