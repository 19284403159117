:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
    --about-background-color: #ffffff;
    --about-text-color: #333333;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    --about-background-color: rgb(24, 24, 24);
    --about-text-color: white;
}


.projects-title {
    padding: 20px;
    margin-left: 65px;
    color: var(--text-color);
    font-size: 3rem;
    background-color: var(--background-color);
    margin-top: -20px;
    margin-bottom: -20px;
    margin-left: -20px;
    padding-left: 65px;
}

.projects-list {
    list-style-type: none;
    padding: 0;
}

.project-item {
    display: flex;
    width: 100%;
    background-color: #f1f1f1;
    color: rgb(0, 0, 0);
    justify-content: space-between;
    padding: 2rem;
    box-sizing: border-box;
}

.project-info {
    padding: 20px;
    width: 30%;
    margin-left: 5rem;
    box-sizing: border-box;
}


.project-info h3 {
    font-size: 3rem;
    font-weight: bolder;
}

.project-info p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
}

.project-info h4 {
    font-size: 1.5rem;
    font-weight: bold;
}

.project-info ul {
    font-size: 1.5rem;
}

.project-image {
    flex: 1;
    text-align: right;
    max-width: 800px;
    /* padding-right: 8rem; */
    padding: 50px;
}

.project-image img {
    max-width: 100%;
    min-width: 200px;
    height: auto;
    /* padding: 50px; */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);

}

.project-link {
    margin-top: 1rem;
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
    padding: 10px;
    border-radius: 4px;
    /* background-color: #ffffff; */
    border: 1px solid #ffffff;
}

ul {
    padding: 20px;
}

.project-item.color-1 {
    background: #264653;
    color: #ffffff;
}

.project-item.color-1 p {
    color: #ffffff;
}

.project-item.color-2 {
    background: #2A9D8F;
}

.project-item.color-2 a {
    color: #000000;
}

.project-item.color-3 {
    background: #E9C46A;
}

.project-item.color-3 a {
    color: #000000;
}

.project-item.color-4 {
    background: #F4A261;
}

.project-item.color-4 a {
    color: #000000;
}

.project-item.color-5 {
    background: #E76F51;
}

.project-item.color-5 a {
    color: #000000;
}

/* Add the following CSS to adjust the scroll offset */
.projects-container:before {
    content: '';
    display: block;
    height: 100px;
    /* Adjust the height as needed */
    margin-top: -100px;
    /* Adjust the negative margin to offset the scroll */
    visibility: hidden;
}

@media screen and (max-width: 1400px) {
    .project-item {
        flex-direction: column;
        align-items: center;
    }

    .project-info {
        width: 100%;
        margin-left: 0;
    }

    .project-image {
        text-align: center;
    }

    .project-image img {
        height: auto;
        width: 50rem;
        /* padding: 10px; */
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    }
}