:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
    --about-background-color: #ffffff;
    --about-text-color: #333333;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    --about-background-color: rgb(24, 24, 24);
    --about-text-color: white;
}

#recent-posts-section {
    color: var(--text-color);
}

.blog-posts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blog-posts-section {
    background-color: var(--background-color);
    padding: 200px;
    margin-top: -20px;
}

.card {
    width: 400px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 1rem;
}

p.card-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: var(--text-color);
}

.card:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.8);
}

.card-image {
    width: 100%;
    height: auto;
}

.card-content {
    padding: 20px;
}

.card-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}

@media (max-width: 800px) {


    .section {
        padding: 40px;
    }
}