@import '../index.css';

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.header::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: url("../images/background.jpg"); */
    background-size: cover;
    z-index: -1;
    opacity: 0.8;
    animation: twinkling 10s infinite;
}

header {
    margin: 0;
    padding: 0;
    background-color: #000;
}



.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
}

.text-container h1 {
    margin: 0;
    font-size: 10vw;
    color: rgba(225, 225, 225, .01);
    background-image: url("../images/su-san-lee-g3PyXO4A0yc-unsplash.jpg");
    background-repeat: repeat;
    -webkit-background-clip: text;
    animation: animate 140s ease-in-out infinite;
    text-align: center;
    text-transform: uppercase;
    font-weight: 900;
}

@keyframes animate {

    0%,
    100% {
        background-position: left top;
    }

    25% {
        background-position: right bottom;
    }

    50% {
        background-position: left bottom;
    }

    75% {
        background-position: right top;
    }
}

/* =========================== */



.center-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 100px; */
    margin: 5rem;
}

.roll-text h2 {
    /* font-family: 'Roboto', sans-serif; */
    font-size: 5vw;
    font-weight: 900;
    color: #5c5c60;
    text-align: center;
    /* text-transform: uppercase; */
    letter-spacing: 1.3px;
    margin: 0;
    padding: 20px;
}

@media (max-width: 800px) {
    .center-container {
        flex-direction: column;
        padding: 50px;
    }

    .button {
        margin: 0 auto;
        font-size: 20px;
        padding: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wrap {
        margin: 0 auto;
    }

    .picture {
        margin: 0 auto;
    }

    .picture img {
        margin: 0 auto;
        width: 200px;
        height: 200px;
    }
}



.button {
    min-width: 250px;
    min-height: 50px;
    font-family: "Nunito", sans-serif;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: 1.3px;
    font-weight: 700;
    color: #313133;
    background: #4fd1c5;
    background: linear-gradient(90deg,
            rgb(234, 131, 83) 0%,
            rgba(79, 209, 197, 1) 100%);
    border: none;
    border-radius: 1000px;
    transition: all 0.3s ease-in-out 0s;
    cursor: pointer;
    outline: none;
    position: relative;
    padding: 20px;
    text-decoration: none;
}

.button::before {
    content: "";
    border-radius: 1000px;
    min-width: calc(340px + 12px);
    min-height: calc(80px + 12px);
    border: 6px solid #00ffcb;
    box-shadow: 0 0 60px rgba(194, 236, 228, 0.64);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
}

.button::after {
    content: "";
    width: 60px;
    height: 60px;
    border-radius: 100%;
    border: 6px solid #00ffcb;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 2.5s infinite;
}

.button:hover {
    background: #00ffcb;
    background: linear-gradient(90deg,
            rgb(235, 171, 141) 0%,
            rgb(148, 226, 218) 100%);
}

.wrap {
    /* padding-top: 50px; */
    display: flex;
    justify-content: center;
}



@media only screen and (max-width: 800px) {

    .center-container {
        padding: 0px;
    }
}