:root {
    --background-color: rgba(255, 255, 255, 0.5);
    --text-color: black;
    --about-background-color: #ffffff;
    --about-text-color: #333333;
}

body.dark-mode {
    --background-color: rgb(34, 34, 34);
    --text-color: white;
    --about-background-color: rgb(24, 24, 24);
    --about-text-color: white;
}



.about-section {
    background-color: var(--about-background-color);
    padding: 200px;
    /* margin: 20px 0px 20px 0px; */
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.section h2 {
    font-size: 32px;
    margin-bottom: 20px;
    color: var(--text-color);
}

.section p {
    font-size: 18px;
    line-height: 1.6;
    color: var(--text-color);
}

.section-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
}

.picture {
    justify-content: center;
    display: flex;
    /* margin-top: 50px; */

}

.picture img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 50%;
}

.socials {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.socials a {
    margin: 0 10px;
    font-size: 24px;

}

.socials a:hover {
    font-size: 2rem;
}

.link-text {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    font-size: 2rem;
    font-weight: bold;
    color: var(--text-color);
}

a.linkedin-link {
    text-decoration: none;
    color: #0e76a8;
    font-weight: bold;
}

a.github-link {
    text-decoration: none;
    color: var(--text-color);
    font-weight: bold;
}


@media (max-width: 800px) {
    .about-section {
        padding: 40px;
    }

    .section h2 {
        font-size: 28px;
    }

    .section-content {
        grid-template-columns: 1fr;
    }

    .picture {
        margin: 0 auto;
    }

    .picture img {
        margin: 0 auto;
        width: 200px;
        height: 200px;
    }
}